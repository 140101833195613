<template>
  <component 
    :is="clickable ? 'a' : 'div'" 
    @click="clickable ? goToArticle(article) : null"
    @auxclick="clickable ? goToArticle(article) : null"
  >
    <el-row 
      :type="orientation == 'landscape' ? 'flex': null" 
      class="article-summary"
      :class="[orientation, size, backgroundClass, isClickable]"
      v-loading="isLoading"
    >
      <el-image
        v-if="hidePhoto == false"
        lazy
        :src="imgUrl"
        fit="cover"
        alt="article summary picture"
      >
        <div slot="error">
          <el-image
            class="error-photo"
            :src="emptyPhotoUrl"
            fit="scale-down"
            alt="article summary picture"
          />
        </div>
        <div slot="placeholder">
          <el-image
            v-loading="true"
            class="error-photo"
            :src="emptyPhotoUrl"
            fit="scale-down"
            alt="article summary picture"
          />
        </div>
      </el-image>

      <div class="summary-detail">
        <div class="upper-part">
          <div v-if="article.name != null && !hideName" class="text-primary text-bold article-title">
            {{ article.name | translate(null,$route.query.locale) }}
          </div>
          <h2 v-if="article.title != null">
            {{ article.title | translate(null,$route.query.locale) }}
          </h2>
          <p class="text-grey" v-if="hideDescription != true && article.description != null"> {{ article.description | translate(null,$route.query.locale) }} </p>
        </div>
        <el-row class="lower-part" type="flex" align="middle">
          <p v-if="withAuthor" class="mr-10">
            By {{ getFullName(article.user) }}
          </p>
          <template v-if="hideTime != true">
            <i class="custom-icon icon-clock-outline mini"/>
            <p class="text-grey time-elapsed">{{ timeElapsed }}</p>
          </template>
          <!-- Remove redirect category first, if client need then only add in, currently cannot use it with router-link
          @auxclick="goTo('/category/'+ `${article.category.id}`, true)" 
          @click="goTo('/category/'+ `${article.category.id}`)"  -->
          <el-divider v-if="!(hideCategory || hideTime)" direction="vertical"/>
          <p v-if="hideCategory != true" class="text-primary text-bold" >
            {{ article.category | translate }}
          </p>

          <template v-if="showFavoriteBtn">
            <el-divider direction="vertical"/>
            <div 
              v-loading="isFavouriting"
              class="favourite-section"
              @mouseover="favouriteMousedOver = true"
              @mouseleave="favouriteMousedOver = false"
              @click="toggleFavourite"
            >
              <i 
                class="favorite-icon custom-icon icon-favourite mini"
                :class="
                  (shouldFavorited || isFavourited || favouriteMousedOver) 
                  ? 'icon-favourited' 
                  : 'icon-favourite'
                "
              />
              <p
                :class="(shouldFavorited || isFavourited) ? 'favourited-text' : ''"
              >
                Favourite
              </p>
            </div>
          </template>

          <article-like-dislike-section
            v-if="showLikeDislikeSection"
            :article="article"
            @onInteractionChange="(interaction) => article.interaction = interaction"
          />
          
        </el-row>
      </div>
    </el-row>
  </component>
</template>


<script>
import { generalMixin } from "@/utils/general-mixin.js"
import { articleMixin } from "@/utils/_mixins/article.js"
import { timeElapsed } from "@/utils/helpers.js"
import { createArticleInteraction } from "@/api/article";
import articleLikeDislikeSection from './article-like-dislike-section.vue';

const emptyPhotoUrl = require('@/assets/pngs/no-image-placeholder.png')

export default {
  components: { articleLikeDislikeSection },
  name: "ArticleSummary",
  mixins: [ generalMixin, articleMixin ],
  props: {
    orientation: {
      type: String,
      required: true,
      validator(value) {
        return ["portrait", "landscape"].includes(value)
      }
    },
    size: {
      type: String,
      required: true,
      validator(value) {
        return ["big", "small"].includes(value)
      }
    },
    articleObj: { type: Object, required: true }, // initial value, will update if have interaction    
    withAuthor: { type: Boolean, default: false },
    withWhiteBg: { type: Boolean, default: false },
    withShadow: { type: Boolean, default: false },
    clickable: { type: Boolean, default: false },
    hidePhoto: { type: Boolean, default: false },
    hideName: { type: Boolean, default: false },
    hideDescription: { type: Boolean, default: false },
    hideTime: { type: Boolean, default: false },
    hideCategory: { type: Boolean, default: false },
    showLikeDislikeSection: { type: Boolean, default: false },
    showFavoriteBtn: { type: Boolean, default: false },
    shouldFavorited: { type: Boolean, default: false },
  },
  watch: {
    articleObj: {
      handler(newV) {
        this.article = newV
      }
    }
  },
  data() {
    return {
      emptyPhotoUrl,
      article: {},
      isLoading: true,
      favouriteMousedOver: false,
      isFavouriting: false
    }
  },
  computed: {
    backgroundClass() {
      let result = `bg-${this.withWhiteBg ? "white" : "transparent"}`
      if (this.withShadow) result += " shadowed"

      return result
    },
    isClickable() {
      return this.clickable ? 'pointer' : null
    },
    timeElapsed() {
      return timeElapsed(this.article.publishDate)
    },
    imgUrl() {
      return this.article.coverPhoto?.upload?.url
    },
    isFavourited() {
      return this.article.interaction?.isUserFavourited
    }
  },
  methods: {
    toggleFavourite(e) {
      e.stopPropagation()

      if (this.isAuthenticated == false) {
        this.goTo('/auth/sign-in')
        return
      }

      this.isFavouriting = true

      let payload = {
        actionType: "FAVOURITE"
      }

      if (this.shouldFavorited || this.isFavourited) {
        payload.unassignArticleIdList = [this.article.id]
        
      } else {
        payload.assignArticleIdList = [this.article.id]
      }
      
      createArticleInteraction(payload).then((res) => {
        if (res.status != "ok") return

        this.article.interaction = res.result.interaction
        this.$emit("refresh", res.result.interaction)

      }).finally(() => {
        this.isFavouriting = false
      })
    }
  },
  mounted() {
    this.article = this.articleObj 
    this.isLoading = false
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/general.scss";

.article-summary {
  border-radius: 5px;
  height: 100%;
  position: relative;

  .mr-10 {
    margin-right: 10px;
  }

  h2 { font-family: $fontBaseBold; }

  // the clock icon
  .custom-icon.mini.icon-clock-outline {
    height: 16px;
    width: 12px;
    background-size: 12px 16px;
  }

  // font
  .upper-part h2 { @include truncateForRow(2) }
  .upper-part p { 
    @include truncateForRow(2);
    font-size: 12px;
   }
  &.big { 
    .upper-part {
      h2 { 
        font-size: 24px;
        line-height: 30px;
      }
      p { 
        font-size: 14px;
        line-height: 20px;
        margin-top: 4px;
      }
    }
  }
  &.small {
    .upper-part h2 { 
      font-size: 22px;
      line-height: 25px;
    }
  }

  // image
  .el-image {
    overflow: hidden;
    height: auto;
    width: auto;
    width: 100%;
  }
  &.big {
    &.portrait{
      .el-image {
        height: 22rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &.landscape { 
      .el-image {
        max-height: 26rem;
        min-height: 26rem;
        max-width: 26rem;
        min-width: 26rem;
      }
    }
  }
  &.small {
    &.portrait{
      .el-image {
        height: 10.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &.landscape { 
      .el-image {
        max-height: 10rem;
        min-height: 10rem;
        max-width: 10rem;
        min-width: 10rem;
      }
    }
  }
  &.portrait .el-image {
    border-radius: 5px 5px 0 0;
  }
  &.landscape .el-image {
    border-radius: 5px 0 0 5px;
  }
  .error-photo img { 
    height: 70px;
    opacity: 0.6;
    margin-top: 40px;
  }

  .favourite-section {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    padding: 6px 8px;
    border-radius: 5px;
    color: #868588;

    &:hover {
      background: #F0F0F0;
      color: #383838;
    }

    .icon-favourite {
      margin-right: 3px;
    }

    .favourited-text {
      font-style: italic;
    }
  }

  // inside details
  .summary-detail {
    .upper-part {
      padding-bottom: 0rem;
      .text-bold {
        font-size: 13px;
        margin-bottom: 0.5rem;
      }
      .article-title {
        @include truncateForRow(1)
      }
    }
    .lower-part {
      position: relative;
      top: 10px;
    }
  }
  &.big .summary-detail {
    margin: 10px 1rem 1.5rem 1rem;
    .upper-part {
      >h2 {
        margin-bottom: 0.3rem;
      }
    }
  }
  &.small .summary-detail {
    margin: 0.65rem 1rem;
    min-height: 5rem;
    padding-bottom: 0.5rem;
    .upper-part {
      > p {
        margin-top: 0.25rem;
      }
    }
  }
  .lower-part {
    font-size: 12px;
    .time-elapsed {
      margin-left: 4px;
    }
  }

}

// mobile view
.mobile {
  .article-summary {
    &.portrait { padding-bottom: 0.3rem }

    .summary-detail {
      font-size: 0.7rem;
      margin-bottom: 0.7rem;
      padding-top: 0;
      .upper-part {
        >*:first-child { margin-top: 0 }
      }
    }
    &.landscape .summary-detail {
      font-size: 0.7rem;
      h2 { font-size: 0.7rem }
    }
    .lower-part {
      line-height: 20px;
    }
  }
}
</style>